import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import { VePagination } from "vue-easytable";

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.use(VueEasytable);
Vue.use(VePagination);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
