<template>
  <div class="home">
    <img src="@/assets/avatar.jpg" alt="Avatar" class="avatar" />
    <h1>这里是滑滑的个人网站</h1>
    <p>关于我：幼儿园水平画师，一般路过音游玩家，写bug程序员，70%enfp</p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.home{
  max-width:90%;
  margin:0 auto;
}

h1 {
  font-size: 1.6rem;
  margin: 20px 0 0;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
</style>
