<template>
  <div id="app">
    <div id="nav" v-show="!this.independentPages.includes(this.$route.path)">
      <router-link to="/">简介</router-link>|
      <router-link to="/diary">日志</router-link>|
      <router-link to="/picture">相册</router-link>|
      <router-link to="/tool">工具</router-link>|
      <router-link to="/link">友链</router-link>
    </div>
    <router-view />
    <div id="footer">
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006221"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="./assets/备案图标.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            粤ICP备2022016609号-1
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      independentPages: ["/admin", "/ali-admin"],
    };
  },
};
</script>

<style>
html {
  overflow-y: overlay;
  background: #f9fafb;
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;
}

#nav {
  padding: 20px;
  margin: 0 auto;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#footer {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 800px;
}
</style>
